window.cargarMunicipios = function (idMunicipios) {
  var valor = $("#form-widgets-provincia").val();

  //Limpio los valores que tuviera el select
  try {
    document.getElementById(idMunicipios).options.length = 0;
    let textSelected = $(
      "select#form-widgets-provincia option:selected",
    ).text();
    if (
      $("select#form-widgets-provincia").prop("disabled") &&
      !textSelected.includes("acotar") &&
      !textSelected.includes("resultados")
    ) {
      $("#form-widgets-provincia").prop("disabled", false);
    }
    $("#poblacion-search").val("");
  } catch (e) {}

  if (valor == "" || valor == "--NOVALUE--" || valor == null) {
    //Se ha seleccionado la opción todas las materias
    //Añado la primera opción
    try {
      $("#form-widgets-poblacion").empty();
      $("#form-widgets-poblacion").options[0] = new Option(
        "Seleccione antes una provincia",
        "--NOVALUE--",
      );
    } catch (e) {
      try {
        document.getElementById("form-widgets-poblacion").options.length = 0;
        document.getElementById("form-widgets-poblacion").options[0] =
          new Option("Seleccione antes una provincia", "--NOVALUE--");
      } catch (e) {}
    }
  } else {
    //Añado la primera opción
    $("#form-widgets-poblacion").empty();
    try {
      $("#form-widgets-poblacion").options[0] = new Option(
        "Elija un municipio",
        "--NOVALUE--",
      );
      cargarSelectMunicipios(valor, idMunicipios);
    } catch (e) {
      document.getElementById("form-widgets-poblacion").options[0] = new Option(
        "Elija un municipio",
        "--NOVALUE--",
      );
      cargarSelectMunicipios(valor, idMunicipios);
    }
  }
};

window.cargarProvincias = function (idProvincia) {
  document.getElementById(idProvincia).options.length = 0;
  $("#form-widgets-provincia").prop("disabled", false);

  document.getElementById(idProvincia).options[0] = new Option(
    "Elija una provincia",
    "--NOVALUE--",
  );
  $('#form-widgets-poblacion option[value="--NOVALUE--"]').prop(
    "selected",
    true,
  );
  cargarSelectProvincias(idProvincia);
  cargarMunicipios("form-widgets-poblacion");
};

window.cargarMunicipiosExtranjeros = function (idMunicipios) {
  var valor = $("#form-widgets-pais").val();

  //Limpio los valores que tuviera el select de submaterias
  if (document.getElementById(idMunicipios) != null) {
    document.getElementById(idMunicipios).options.length = 0;
    if (document.getElementById("#form-widgets-provincia") != null) {
      document.getElementById("#form-widgets-provincia").options.length = 0;
    }
    if (valor == "" || valor == "--NOVALUE--") {
      //Se ha seleccionado la opción todas las materias
      //Añado la primera opción
      document.getElementById(idMunicipios).options[0] = new Option(
        "Seleccione antes una provincia",
        "--NOVALUE--",
      );
    } else {
      //Añado la primera opción
      document.getElementById(idMunicipios).options[0] = new Option(
        "Elija un municipio",
        "--NOVALUE--",
      );
      cargarSelectMunicipiosExtranjeros(valor, idMunicipios);
    }
  }
};

window.cargarSelectProvincias = function (idProvincias) {
  var base_url = location.protocol + "//" + location.host;
  var nodo = location.href.split(base_url)[1].split("/")[1];

  $("#" + idProvincias).prepend(
    "<option value='' selected='selected'>Cargando provincias...</option>",
  ); // LOADING
  $("#provincia-search-alert").text("Cargando provincias");
  $("#" + idProvincias).prop("disabled", true);

  // Obtengo las provincias a traves de la provincia
  // TODO: get portal_url and pass it.
  $.ajax({
    type: "POST",
    url:
      location.protocol +
      "//" +
      location.host +
      "/" +
      nodo +
      "/@@obtener-provincias",
    async: true,
    data: {},
    success: function (data, status) {
      let provinciasJSON = eval(data);
      window[idProvincias] = [];
      //Recorremos las municipios y las añadimos al select correspondiente
      for (var key in provinciasJSON) {
        var item = provinciasJSON[parseInt(key)];
        var attrName = item[0];
        var attrValue = item[1];
        $("#" + idProvincias).append(new Option(attrValue, attrName));
        window[idProvincias].push({ value: attrName, text: attrValue });
      }
      $("#" + idProvincias)
        .find("option")
        .eq(0)
        .remove(); // FINISH LOADING
      $("#" + idProvincias).prop("disabled", false);
      $("#provincia-search-alert").text("Se han cargado las provincias");
    },
  });
};

window.cargarSelectMunicipios = function (valor, idMunicipios) {
  var base_url = location.protocol + "//" + location.host;
  var nodo = location.href.split(base_url)[1].split("/")[1];
  $("#" + idMunicipios).prepend(
    "<option value='' selected='selected'>Cargando poblaciones...</option>",
  ); // LOADING
  $("#poblacion-search-alert").text("Cargando poblaciones");
  // Lo comentamos porque no se veia el buscador de poblaciones
  //   $("#" + idMunicipios).prop("disabled", true);

  // Obtengo las municipios a traves de la provincia
  // TODO: get portal_url and pass it.
  $.ajax({
    type: "POST",
    url:
      location.protocol +
      "//" +
      location.host +
      "/" +
      nodo +
      "/@@obtener-municipios",
    async: true,
    data: { cd_provincia: valor },
    success: function (data, status) {
      var municipiosJSON = eval(data);
      window[idMunicipios] = [];
      if (municipiosJSON.length <= 100) {
        //Recorremos las municipios y las añadimos al select correspondiente
        for (var key in municipiosJSON) {
          var item = municipiosJSON[parseInt(key)];
          var attrName = item[0];
          var attrValue = item[1];
          $("#" + idMunicipios).append(new Option(attrValue, attrName));
          window[idMunicipios].push({ value: attrName, text: attrValue });
        }
        $("#" + idMunicipios)
          .find("option")
          .eq(0)
          .remove(); // FINISH LOADING
        $("#" + idMunicipios).prop("disabled", false);
        $("#poblacion-search-alert").text("Se han cargado las poblaciones");

        if (
          $("#form-widgets-poblacion").val() != "" &&
          $("#form-widgets-poblacion").val() != "--NOVALUE--"
        ) {
          var pob = $("#form-widgets-poblacion").val();
        } else {
          var pob = $("#form-widgets-pob").val();
        }
        try {
          $('#form-widgets-poblacion option[value="' + pob + '"]').prop(
            "selected",
            true,
          );
        } catch (e) {}
      } else {
        var pobText = "";
        if (
          $("#form-widgets-poblacion").val() != "" &&
          $("#form-widgets-poblacion").val() != "--NOVALUE--"
        ) {
          var pob = $("#form-widgets-poblacion").val();
        } else {
          var pob = $("#form-widgets-pob").val();
        }

        for (var key in municipiosJSON) {
          var item = municipiosJSON[parseInt(key)];
          window[idMunicipios].push({ value: item[0], text: item[1] });
          if (pob && pob == item[0]) pobText = item[1];
        }
        if (pobText) {
          // Edit load, value combo
          $("#" + idMunicipios).empty();
          $("#" + idMunicipios).prepend(
            "<option value='" +
              pob +
              "' selected='selected'>" +
              pobText +
              "</option>",
          );
          $("#" + idMunicipios).prop(
            "title",
            "Para ver más poblaciones use el buscador asociado.",
          );
          $("#poblacion-search-alert").text(
            "Para ver más poblaciones use el buscador asociado.",
          );
          $("#" + idMunicipios).prop("disabled", false);
        } else {
          $("#" + idMunicipios)
            .find("option")
            .eq(0)
            .remove(); // FINISH LOADING
          $("#" + idMunicipios).prepend(
            "<option value='' selected='selected'>Por favor, debe acotar la búsqueda</option>",
          );
          $("#" + idMunicipios).prop(
            "title",
            "Para ver más poblaciones use el buscador asociado.",
          );
          $("#poblacion-search-alert").text(
            "Por favor, debe acotar la búsqueda",
          );
        }
      }
    },
  });
};

window.cargarSelectMunicipiosExtranjeros = function (valor, idMunicipios) {
  var base_url = location.protocol + "//" + location.host;
  var nodo = location.href.split(base_url)[1].split("/")[1];

  $("#" + idMunicipios).prepend(
    "<option id='charching-cities' value='' selected='selected'>Cargando poblaciones...</option>",
  ); // LOADING
  $("#poblacion-search-alert").text("Cargando poblaciones");

  // Obtengo las municipios a traves de la provincia
  // TODO: get portal_url and pass it.
  $.ajax({
    type: "POST",
    url:
      location.protocol +
      "//" +
      location.host +
      "/" +
      nodo +
      "/@@obtener-municipios-extranjeros",
    async: true,
    data: { cd_pais: valor },
    success: function (data, status) {
      var municipiosJSON = eval(data);
      document.getElementById("form-widgets-provincia").options.length = 0;
      document.getElementById("form-widgets-provincia").options[0] = new Option(
        "EXTRANJERO",
        "99",
      );
      window[idMunicipios] = [];
      window["form-widgets-provincia"] = [];
      window["form-widgets-provincia"].push({
        value: "99",
        text: "EXTRANJERO",
      });
      //Recorremos los municipios y las añadimos al select correspondiente
      for (var key in municipiosJSON) {
        var municipio = municipiosJSON[parseInt(key)];
        var attrName = municipio.LCO_CodigoOnce;
        var attrValue = municipio.LCO_Descripcion;
        $("#" + idMunicipios).append(new Option(attrValue, attrName));
        window[idMunicipios].push({ value: attrName, text: attrValue });
      }
      $("#charching-cities").remove();
      $("#" + idMunicipios).prop("disabled", false);
      $("#poblacion-search-alert").text("Se han cargado las poblaciones");

      $('#form-widgets-provincia option[value="99"]').prop("selected", true);
      $("#form-widgets-provincia").prop("disabled", true);

      if (
        $("#form-widgets-poblacion").val() != "" &&
        $("#form-widgets-poblacion").val() != "--NOVALUE--"
      ) {
        var pob = $("#form-widgets-poblacion").val();
      } else {
        var pob = $("#form-widgets-pob").val();
      }
      try {
        $('#form-widgets-poblacion option[value="' + pob + '"]').prop(
          "selected",
          true,
        );
        if (!isNaN(pob)) {
          $("#formfield-form-widgets-poblacion").removeClass("error");
          $("#form-widgets-poblacion-error div.error").remove();
          $("#formfield-form-widgets-poblacion .invalid-feedback").remove();
        }
      } catch (e) {}
    },
  });
};

$(document).ready(function () {
  var idPais = $("#form-widgets-pais").val();
  var idProvincia = $("#form-widgets-provincia").val();
  var idMunicipios = $("#form-widgets-poblacion");
  $("#form-widgets-codPos-error div.error").remove();

  if (idPais == "011") {
    $("#label-form-widgets-codPos").append(
      '<span class="requiredAsterisco">*</span>',
    );
    $("#form-widgets-codPos").attr(
      "aria-labelledby",
      "label-form-widgets-codPos",
    );
    $("#form-widgets-codPos").attr("aria-invalid", "false");
    $("#form-widgets-codPos").attr("aria-required", "true");
    if (idMunicipios != null && idProvincia != null) {
      cargarMunicipios("form-widgets-poblacion");
    }
  } else if (idPais != null) {
    $("#label-form-widgets-codPos span.requiredAsterisco").remove();
    $("#form-widgets-codPos").removeAttr("aria-labelledby");
    $("#form-widgets-codPos").removeAttr("aria-invalid");
    $("#form-widgets-codPos").removeAttr("aria-required");
    if (
      idPais == null ||
      idPais == "--NOVALUE--" ||
      idPais == "" ||
      idPais == "0"
    ) {
      try {
        $("#form-widgets-provincia").empty();
        document.getElementById("form-widgets-provincia").options[0] =
          new Option("Seleccione antes un país", "--NOVALUE--");
      } catch (e) {}
    }
    if (idMunicipios != null) {
      cargarMunicipiosExtranjeros("form-widgets-poblacion");
    }
  }

  $("#form-widgets-pais").change(function () {
    var idPais = $("#form-widgets-pais").val();
    var idProvincia = $("#form-widgets-provincia").val();
    var idMunicipios = $("#form-widgets-poblacion");
    $("#provincia-search").val("");
    $("#poblacion-search").val("");
    if (idPais == "011") {
      if ($("#label-form-widgets-codPos > span.requiredAsterisco").length == 0)
        $("#label-form-widgets-codPos").append(
          '<span class="requiredAsterisco">*</span>',
        );
      $("#form-widgets-codPos").attr(
        "aria-labelledby",
        "label-form-widgets-codPos",
      );
      $("#form-widgets-codPos").attr("aria-invalid", "false");
      $("#form-widgets-codPos").attr("aria-required", "true");
      if (idMunicipios != null && idProvincia != null) {
        cargarProvincias("form-widgets-provincia");
        cargarMunicipios("form-widgets-poblacion");
      }
    } else {
      $("#label-form-widgets-codPos span.requiredAsterisco").remove();
      $("#form-widgets-codPos").removeAttr("aria-labelledby");
      $("#form-widgets-codPos").removeAttr("aria-invalid");
      $("#form-widgets-codPos").removeAttr("aria-required");
      if (
        idPais == null ||
        idPais == "--NOVALUE--" ||
        idPais == "" ||
        idPais == "0"
      ) {
        try {
          $("#form-widgets-provincia").prop("disabled", false);
          $("#form-widgets-provincia").empty();
          document.getElementById("form-widgets-provincia").options[0] =
            new Option("Seleccione antes un país", "--NOVALUE--");
        } catch (e) {}
      }
      if (idMunicipios != null) {
        cargarMunicipiosExtranjeros("form-widgets-poblacion");
      }
    }
  });
});

$(document).on("submit", "#form", function () {
  $("#form-widgets-provincia").prop("disabled", false);
  if ($("#formfield-form-widgets-codPos").hasClass("error")) {
    $("#form-widgets-codPos").attr(
      "aria-labelledby",
      "label-form-widgets-codPos form-widgets-codPos form-widgets-codPos-error",
    );
    $("#form-widgets-codPos").attr("aria-invalid", "true");
    if (!$("#form-widgets-codPos-error").find("div.error").length) {
      $("#form-widgets-codPos-error").append(
        '<div class="error">Faltan datos obligatorios.</div>',
      );
    }
  }
});
