// Archivo JScript
$(document).ready(function () {
  createSelectSearch(
    "pais-search",
    "form-widgets-pais",
    "formfield-form-widgets-pais",
    "Buscador de paises",
    "Buscar país...",
    true,
    false,
  );
  createSelectSearch(
    "provincia-search",
    "form-widgets-provincia",
    "formfield-form-widgets-provincia",
    "Buscador de provincias",
    "Buscar provincia...",
    true,
    false,
  );
  createSelectSearch(
    "poblacion-search",
    "form-widgets-poblacion",
    "formfield-form-widgets-poblacion",
    "Buscador de poblaciones",
    "Buscar población...",
    false,
    true,
  );
});

function createSelectSearch(
  idSearch,
  idSelect,
  idDivSelect,
  title,
  placeholder,
  flagLoad,
  limit,
) {
  // Generate input search
  var select = $("#" + idSelect);

  // Set aria-live to off to avoid noisy searchs
  select.attr("aria-live", "off");

  // Get the parent form
  var form = select.parents("form");
  // Check if form has not class 'exclude-dinamic-location' to render search fields
  if ($(form).hasClass("exclude-dinamic-location") == false) {
    // Check if select exists and is not disabled to render search fields
    if (select.length > 0 && select.prop("disabled") == false) {
      $("#" + idDivSelect).before(
        '<div class="once-left-search input-group mb-3">' +
          '<label for="form-widgets-' +
          idSearch +
          '" class="horizontal visually-hidden form-label" id="label-form-widgets-' +
          idSearch +
          '">' +
          title +
          "</label>&nbsp;" +
          '<span class="input-group-text" aria-hidden="true"> \
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-search" viewBox="0 0 16 16"> \
                    <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/> \
                </svg> \
                </span>' +
          '<input class="form-control form-control-sm" placeholder="' +
          placeholder +
          '" type="text" id="' +
          idSearch +
          '">' +
          "</div>",
      );
    }
  }

  // Generate div alerts
  $("#" + idDivSelect).append(
    '<div class="sr-only" id="' +
      idSearch +
      '-alert" role="alert" aria-atomic="false" aria-live="off"></div>',
  );

  // Set dynamic global var for multiples selects.
  // flagLoad - true indicates that the select is already loaded, false that it is loaded by onchange
  if (flagLoad) {
    data = [];
    $("select#" + idSelect + "> option").each(function () {
      data.push({ value: $(this).val(), text: $(this).text() });
    });
    window[idSelect] = data;
  } else {
    window[idSelect] = [];
  }
  eventSelectSearch(idSearch, idSelect, limit);
  // }
}

function eventSelectSearch(idSearch, idSelect, limit) {
  $("#" + idSearch).keyup(function (e) {
    textSelected = $("select#" + idSelect + " option:selected").text();
    if (
      ($("select#" + idSelect).prop("disabled") &&
        !textSelected.includes("acotar") &&
        !textSelected.includes("resultados")) ||
      e.key == "Tab" ||
      e.key == "Shift"
    )
      // Disabled select || tab key || shitf
      return;

    $("select#" + idSelect).empty();
    var inputValue = $(this).val();
    data = window[idSelect];

    if (limit) {
      // Limit - true: 100 results for view
      var data_find = [];
      data.forEach(function (val) {
        if (val.text.toLowerCase().indexOf(inputValue.toLowerCase()) != -1) {
          data_find.push(val);
        }
      });
      if (data_find.length > 0 && data_find.length <= 100) {
        data_find.forEach(function (val) {
          $("select#" + idSelect).append(
            $("<option></option>").attr("value", val.value).text(val.text),
          );
        });
        $("#" + idSelect).prop("disabled", false);
        $("#" + idSearch + "-alert").text("Se muestran resultados");
      } else {
        $("#" + idSelect).prop("disabled", true);
        if (data_find.length == 0) {
          $("#" + idSelect).prepend(
            "<option value='' selected='selected'>No se han encontrado resultados</option>",
          );
          $("#" + idSearch + "-alert").text("No se han encontrado resultados");
        } else {
          $("#" + idSelect).prepend(
            "<option value='' selected='selected'>Por favor, debe acotar la búsqueda</option>",
          );
          $("#" + idSearch + "-alert").text(
            "Por favor, debe acotar la búsqueda",
          );
        }
      }
    } else {
      var find = false;
      data.forEach(function (val) {
        if (val.text.toLowerCase().indexOf(inputValue.toLowerCase()) != -1) {
          $("select#" + idSelect).append(
            $("<option></option>").attr("value", val.value).text(val.text),
          );
          find = true;
        }
      });
      if (find) {
        $("#" + idSelect).prop("disabled", false);
        $("#" + idSearch + "-alert").text("Se muestran resultados");
      } else {
        $("#" + idSelect).prop("disabled", true);
        $("#" + idSelect).prepend(
          "<option value='' selected='selected'>No se han encontrado resultados</option>",
        );
        $("#" + idSearch + "-alert").text("No se han encontrado resultados");
      }
    }
    $("select#" + idSelect).trigger("change");
  });
}
